import React, { useEffect, useState } from 'react';
import './YourOrders.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import OrderTrack from './OrderTrack';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { Cart2 } from 'react-bootstrap-icons';
import { useRecoilState } from 'recoil';
import { orderSuccessfulProvider } from '../../Providers/OrderSuccessfulProvider';
import OrderSuccessful from '../OrderSuccessful/OrderSuccessful';

const YourOrders = ({ userid }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [allOrders, setAllOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState({
    isOpen: false,
    id: '',
    message: '',
    data: {},
    items: [],
    subtotal: 0,
    tax: 0,
    shipping: 0,
  });

  const openModal = () => {
    setModalOpen(true);
    window.history.pushState({ modal: true }, 'Order Details');
  };
  // Function to close the modal and handle history state
  const closeModal = () => {
    setModalOpen(false);
    if (window.history.state?.modal) {
      window.history.back(); // Only go back if modal state exists
    }
  };
  // Handle browser back/forward navigation
  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state?.modal) {
        setModalOpen(false);
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const getSuccessfulOrder = (orderId) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/B2CCustomerOrder/Getbycode?OrganizationId=${process.env.REACT_APP_BACKEND_ORGANIZATION}&OrderNo=${orderId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.Status === true && data.Data) {
          const orderData = data.Data[0];
          const items = orderData.OrderDetail;
          let total = 0;
          let tax = 0;
          items.forEach((item) => {
            total += item.Price * item.Qty;
            tax += item.Tax * item.Qty;
          });
          const shipping = total >= 80 ? 0 : total >= 50 ? 3 : 5;
          setOrderDetails({
            isOpen: true,
            id: orderId,
            message: `Order #${orderId}`,
            data: orderData,
            items,
            subtotal: total,
            tax,
            shipping,
          });
        } else {
          toast.error('Error fetching order details');
        }
      })
      .catch(() => {
        toast.error('Error fetching order details');
      });
  }

  const getOrders = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/B2CCustomerOrder/GetHeaderSearch?searchModel.organisationId=3&searchModel.customerCode=${userid}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAllOrders(data.Data);
      })
      .catch(() => {
        toast.error('Failed to fetch orders');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrders();
  }, [userid]);

  // const handleOrderClick = (orderId) => {
  //   // navigate('/order-track', { state: { orderId } });  // Pass the orderId to the OrderTrack page
  // };

  return (
    <div className="yourorders">
      <h1 className="mainhead2">My Order List</h1>
      {loading && <p>Loading orders...</p>}
      <table className="table">
        <thead>
          <tr>
            <th className='text-left'>Order ID</th>
            <th className='text-left'>Order Date</th>
            <th className='text-left'>Order Status</th>
            <th className='text-left'>Order Total</th>
            <th className='text-left'>Action</th>
          </tr>
        </thead>
        <tbody> 
          {allOrders &&
            allOrders.map((item, index) => (
              <tr key={index}>
                <td >{item.OrderNo}</td>
                <td >{item.OrderDateString}</td>
                <td >
                  <p className={item.status === 'Delivered' ? 'delivered' : 'ontheway'}></p>
                  {item.status}
                </td>
                <td>S${item.NetTotal}</td>
                <td>
                  <button className="mainbutton1"
                    onClick={() => {
                      getSuccessfulOrder(item.OrderNo);
                      openModal();
                    }}>
                    <Cart2 size={22} />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {isModalOpen && (
        <OrderSuccessful
          closeModal={() => {
            setOrderDetails({ ...orderDetails, isOpen: false });
            closeModal();
          }}
          orderid={orderDetails.id}
          message={orderDetails.message}
          orderdata={orderDetails.data}
          orderitems={orderDetails.items}
          tax={orderDetails.tax}
          subtotal={orderDetails.subtotal}
          shipping={orderDetails.shipping}
        />
      )}
    </div>
  );
};

export default YourOrders;
