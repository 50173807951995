import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { Grid, Paper, Typography, Button , TextField } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Rating from '@mui/material/Rating';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Modal from '@mui/material/Modal';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Slider from 'react-slick';
import LinearProgress from '@mui/material/LinearProgress';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useRecoilState } from 'recoil'
import { cartQuantity } from '../../Providers/CartQuantity'
import { wishQuantity } from '../../Providers/WishListQuantityProvider'
import { cartReloadState } from '../../Providers/CartReload'
import './ProductCard.css'
import { productPopupProvider } from '../../Providers/ProductpopupProvider'
import ProductPopup from './ProductPopup'
import noimage from '../../ASSETS/noimage.png'
import { productPopupIdProvider } from '../../Providers/ProductPopupIdProvider';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import AuthPopup from '../Auth/AuthPopup';
import { authPopupState } from '../../Providers/AuthPopupProvider';
import logo from '../../ASSETS/loaderGif.gif'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius:'8px',
  p: 4,
  zIndex:9999,
  minHeight: '85vh !important',
};


const CustomPrevArrow = (props) => (
  <div className="custom-arrow custom-prev" onClick={props.onClick}>
    <ArrowBackIosNewIcon />
  </div>
);

const CustomNextArrow = (props) => (
  <div className="custom-arrow custom-next" onClick={props.onClick}>
    <ArrowForwardIosIcon />
  </div>
);


const ProductCard = ({ data, wishlist }) => {

  const [open, setOpen] = React.useState(false);
  const [show, setshow] = useState(false)
  const [count, setCount] = React.useState(1)  
  const [productcode, setproductcode] = useState(data.ProductCode)
  const [showreview, setshowreview] = React.useState(false)

  const [cartdataquantity, setcartdataquantity] = useRecoilState(cartQuantity)
  const [wishlistdataquantity, setwishlistdataquantity] = useRecoilState(wishQuantity)
  const [authPopupShow, setAuthPopupShow] = useRecoilState(authPopupState);

  const [productpopup, setproductpopup] = useRecoilState(productPopupProvider)
  const [productpopupid, setproductpopupid] = useRecoilState(productPopupIdProvider)
  const navigate = useNavigate()

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };


  useEffect(() => {
    // Code to run when the component mounts
    let cart = JSON.parse(localStorage.getItem('cart'));
    if (cart && cart.length > 0) {
      cart.forEach((item) => {
      // console.log("item values", item)
        if (item.data.ProductCode === data.ProductCode && item.quantity > 0) {
          setshow(true);
          setCount(item.quantity);
          getwhishlist();
        }
      });
    }
  }, []);

  const getcartitems = async () => {
    let user = localStorage.getItem('token');
    user = JSON.parse(user);

    if (user) {
      try {
        // console.log("Confirming the API Call");
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/CartDetails/Getbycode?OrganizationId=${process.env.REACT_APP_BACKEND_ORGANIZATION}&Code=${user[0].B2CCustomerId}`);
            const data = await response.json();

            if (data && data.Data) {
                let qty = 0;
              data.Data.forEach((item) => {
                  if (item.Qty > 0) {
                    // console.log("item -- 140 - productcard", item);
                    setshow(true);
                    setCount(item.Qty)
                  }
                  qty += item.Qty;
                });
                setcartdataquantity(qty); 
            }

        } catch (error) {
            console.log("Error fetching cart items from the backend:", error);
        }
    }

    else {
      toast.error("Please login")
        // let cart = JSON.parse(localStorage.getItem('cart'));
        // if (cart !== null) {
        //     let qty = 0;
        //     cart.forEach((item) => {
        //         qty += item.quantity;
        //     });
        //     setcartdataquantity(qty); 
        // } else {
        //     setcartdataquantity(0); 
        // }
    }
};

const addtocart = async () => {
  // Retrieve the user data from localStorage
  let user = localStorage.getItem('token');
  user = JSON.parse(user);

  // If the user is not logged in, show the authentication popup and return
  if (!user) {
      setAuthPopupShow(true);
      return;
  }

  // Get the cart from localStorage, or initialize it if it's empty
    setCount(count ? count : 1);
    
  let cart = JSON.parse(localStorage.getItem('cart')) || [];

  // Find if the item is already in the cart based on the ProductCode
  const itemInCart = cart.find(item => item.data.ProductCode === data.ProductCode);

  // If the item exists in the cart, increase the quantity
  if (itemInCart) {
      itemInCart.quantity += count ? count : 1;
  } else {
      // Otherwise, add a new item to the cart
      cart.push({ data, quantity: count ? count : 1 });
  }

  // Store the updated cart in localStorage (optional - you can enable this if required)
 localStorage.setItem('cart', JSON.stringify(cart));

  try {
      // Make the API call to add the item to the backend cart
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/CartDetails/CreateCartDetails', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              "OrgId": "3",
              "CartCode": data.CartCode,
              "CustomerCode": user[0].B2CCustomerId,  // Pass the CustomerCode from the logged-in user's data
              "Qty": itemInCart ? itemInCart.quantity : count ? count : 1, // Ensure the correct quantity is sent
              "Price": data.Price,
              "SellingCost": data.SellingCost,
              "CreatedUser": "",//user[0].B2CCustomerName
              "ModifiedUser":"",//user[0].B2CCustomerName
              "ProductCode": data.ProductCode,
              "ProductName": data.ProductName,
              "IsActive": true,
              "CreatedUser": data.CreatedUser,
              "ModifiedUser": data.ModifiedUser,
              "ProductImage": data.ProductImage,
              "Createdon": data.CreatedOn,
              "Modifiedon": data.ModifiedOn,
              "ChangedOnString": data.ChangedOnString,
              "CreatedOnString": data.CreatedOnString
          }),
      });

      const responseData = await response.json();

      if (responseData.Code === 200) {
          toast.success('Product added to Cart', {
              position: "top-right",
              autoClose: 1000,
          });
          getcartitems();  // Fetch updated cart items from the backend
      }
  } catch (err) {
      console.error('Error adding to cart:', err);
      toast.error('Product could not be added to cart', {
        position: "top-right",
        autoClose: 1000,
      });
  }
};

const incrementcart = async () => {
  let user = localStorage.getItem('token');
  user = JSON.parse(user);

  if (!user) {
      setAuthPopupShow(true);
      return;
  }

  let cart = JSON.parse(localStorage.getItem('cart')) || [];

  const itemInCart = cart.find(item => item.data.ProductCode === data.ProductCode);

  if (itemInCart) {
      itemInCart.quantity += 1;
  } else {
      cart.push({
          data: data, 
          quantity: 1
      });
  }

 localStorage.setItem('cart', JSON.stringify(cart));

  try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/CartDetails/CreateCartDetails', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              "OrgId": "3",
              "CartCode": data.CartCode,
              "CustomerCode": user[0].B2CCustomerId,  // Pass the CustomerCode from the logged-in user's data
              "Qty": itemInCart ? itemInCart.quantity : 1,  
              "Price": data.Price,
              "SellingCost": data.SellingCost,
              "CreatedUser": "",//user[0].B2CCustomerName
              "ModifiedUser": "",//user[0].B2CCustomerName
              "ProductCode": data.ProductCode,
              "ProductName": data.ProductName,
              "IsActive": true,
              "CreatedUser": data.CreatedUser,
              "ModifiedUser": data.ModifiedUser,
              "ProductImage": data.ProductImage,
              "Createdon": data.CreatedOn,
              "Modifiedon": data.ModifiedOn,
              "ChangedOnString": data.ChangedOnString,
              "CreatedOnString": data.CreatedOnString
          }),
      });

      const responseData = await response.json();

      if (responseData.Code === 200) {
          toast.success('Cart updated successfully', {
              position: "top-right",
              autoClose: 500,
          });
          getcartitems();  
      }
  } catch (err) {
      toast.error('Error updating cart', {
          position: "top-right",
          autoClose: 1000,
      });
      console.error('Error updating cart:', err);
  }

  getcartitems();
  setshow(true);
};
const decrementcartqty = async () => {
  // Retrieve the user data from localStorage
  let user = localStorage.getItem('token');
  user = JSON.parse(user);

  // If the user is not logged in, show the authentication popup and return
  if (!user) {
      setAuthPopupShow(true);
      return;
  }

  // Retrieve cart from localStorage
  let cart = JSON.parse(localStorage.getItem('cart')) || [];
  const itemInCart = cart.find(item => item.data.ProductCode === data.ProductCode);

  if (itemInCart) {
      if (itemInCart.quantity >= 1) {
          // Decrement the quantity and update the cart
          itemInCart.quantity -= 1;
         localStorage.setItem('cart', JSON.stringify(cart));

          try {
              // Call CreateCartDetails API to update the quantity
              const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/CartDetails/CreateCartDetails', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                      "OrgId": "3",
                      "CartCode": data.CartCode,
                      "CustomerCode": user[0].B2CCustomerId, // Use dynamic CustomerCode
                      "Qty": itemInCart.quantity,
                      "Price": data.Price,
                      "SellingCost": data.SellingCost,
                      "CreatedUser": "",//user[0].B2CCustomerName
                      "ModifiedUser":"",//user[0].B2CCustomerName
                      "ProductCode": data.ProductCode,
                      "ProductName": data.ProductName,
                      "IsActive": true,
                      "CreatedUser": data.CreatedUser,
                      "ModifiedUser": data.ModifiedUser,
                      "ProductImage": data.ProductImage,
                      "Createdon": data.CreatedOn,
                      "Modifiedon": data.ModifiedOn,
                      "ChangedOnString": data.ChangedOnString,
                      "CreatedOnString": data.CreatedOnString
                  }),
              });

              const responseData = await response.json();

              if (responseData.Code === 200) {
                  toast.success('Product Removed from Cart', {
                      position: "top-right",
                      autoClose: 500,
                  });
                  getcartitems(); // Refresh the cart
              }
          } catch (err) {
              toast.error('Error updating cart', {
                  position: "top-right",
                  autoClose: 1000,
              });
              console.error('Error updating cart:', err);
          }

      } else if (itemInCart.quantity === 1) {
          // Remove the item if quantity is 1
          try {
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/CartDetails/RemoveByProductCode?OrganizationId=${process.env.REACT_APP_BACKEND_ORGANIZATION}&CustomerCode=${user[0].B2CCustomerId}&ProductCode=${data.ProductCode}`);
              const responseData = await response.json();

              if (responseData.Code === 200) {
                  // Remove the item from the local cart
                  cart = cart.filter(item => item.data.ProductCode !== data.ProductCode);
                 localStorage.setItem('cart', JSON.stringify(cart));

                  toast.success('Product removed from cart', {
                      position: "top-right",
                      autoClose: 1000,
                  });
                  getcartitems(); // Refresh the cart
              }
          } catch (err) {
              toast.error('Error removing product from cart', {
                  position: "top-right",
                  autoClose: 1000,
              });
              console.error('Error removing product from cart:', err);
          }
      }
  }

  // Refresh the cart to reflect the changes
  getcartitems();
};



const incrementcartqty = async () => {
  // Retrieve the user data from localStorage
  let user = localStorage.getItem('token');
  user = JSON.parse(user);

  if (!user) {
      setAuthPopupShow(true);
      return;
  }

  // Get the cart from localStorage
  let cart = JSON.parse(localStorage.getItem('cart')) || [];

  // Find the item to increment in the cart
  const itemInCart = cart.find(item => item.data.ProductCode === data.ProductCode);

  if (itemInCart) {
      itemInCart.quantity += 1;
  } else {
      // If item doesn't exist, you can handle it by adding it to the cart (optional)
      cart.push({
          data: data, 
          quantity: 1
      });
  }

  localStorage.setItem('cart', JSON.stringify(cart));

  try {
      // Make the API call to update the item quantity in the backend cart
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/CartDetails/CreateCartDetails', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              "OrgId": "3",
              "CartCode": data.CartCode,
              "CustomerCode": user[0].B2CCustomerId,  // Pass the CustomerCode from the logged-in user's data
              "Qty": itemInCart.quantity,  // Updated quantity
              "Price": data.Price,
              "SellingCost": data.SellingCost,
              "CreatedUser": "",//user[0].B2CCustomerName
              "ModifiedUser":"",//user[0].B2CCustomerName
              "ProductCode": data.ProductCode,
              "ProductName": data.ProductName,
              "IsActive": true,
              "CreatedUser": data.CreatedUser,
              "ModifiedUser": data.ModifiedUser,
              "ProductImage": data.ProductImage,
              "Createdon": data.CreatedOn,
              "Modifiedon": data.ModifiedOn,
              "ChangedOnString": data.ChangedOnString,
              "CreatedOnString": data.CreatedOnString
          }),
      });

      const responseData = await response.json();

    if (responseData.Code === 200) {
        
          getcartitems();  // Fetch updated cart items from the backend
          toast.success('Cart updated successfully', {
              position: "top-right",
              autoClose: 500,
          });
      }
  } catch (err) {
      console.log('Error updating cart:', err);
      toast.error('Error updating cart', {
          position: "top-right",
          autoClose: 1000,
      });
  }
};


  const [prodid, setprodid] = useState(null)
  const [isinwhishlist, setisinwhishlist] = useState(wishlist)
  
  const addtowhishlist = () => {
    let user = localStorage.getItem('token');
    user = JSON.parse(user);
  
    if (user) {
      fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerWishList/Create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "OrgId": "3",
          "CustomerId": user[0].B2CCustomerId,
          "ProductCode": data.ProductCode,
          "ProductName": data.ProductName,
          "IsActive": true,
          "Quantity":data.qty,
          "Price":data.Price,
          "CreatedBy": user[0].B2CCustomerId,
          "CreatedOn": `${new Date()}`,
        }),
      })
        .then(res => res.json())
        .then(data => {
          if (data.Code === 200) {
            setisinwhishlist(true);
            getwhishlist();
            toast.success('Product added to wishlist', {
              position: "bottom-right",
              autoClose: 1000,
            });
          }
        })
        .catch(err => {
          toast.error('Product could not be able to added to cart', {
            position: "bottom-right",
            autoClose: 1000,
        });
        });
    }
    else {
      toast.error('Please log in to view your wishlist.', {
        position: "top-right",
        autoClose: 1000, 
    });
      setAuthPopupShow(true)
    }
    //localStorage.setItem('wishlist_' + productcode, 'true');
  }
  const getwhishlist = () => {
    let user = localStorage.getItem('token')
    user = JSON.parse(user)
    if (user) {
      fetch(process.env.REACT_APP_BACKEND_URL + `/B2CCustomerWishList/GetByCustomer?OrganizationId=${process.env.REACT_APP_BACKEND_ORGANIZATION}&CustomerId=${user[0].B2CCustomerId}`)
        .then(res => res.json())
        .then(data => {
          if (data.Code == 200) {
            data.Data.forEach((item) => {
              setwishlistdataquantity(data.Data.length)
              if (item.ProductCode === productcode) {
                setisinwhishlist(true)
              }
            })
          }
        })
        .catch(err => {
          console.error(err);
        })
    }
  }

  const removewhishlist = () => {
    let user = localStorage.getItem('token')
    user = JSON.parse(user)
    if (user) {
      fetch(process.env.REACT_APP_BACKEND_URL + `/B2CCustomerWishList/Remove?OrganizationId=${process.env.REACT_APP_BACKEND_ORGANIZATION}&CustomerId=${user[0].B2CCustomerId}&ProductCode=${productcode}&UserName=admin`)

        .then(res => res.json())
        .then(data => {
          if (data.Code == 200) {
            setisinwhishlist(false);
            setwishlistdataquantity(prevQuantity => prevQuantity - 1);
            getwhishlist();
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
   // localStorage.removeItem('wishlist_' + productcode);
  }

  const [PopProducts, setproducts] = useState([]);

  const getProducts = () => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/Product/GetAllWithImageV2?OrganizationId='+process.env.REACT_APP_BACKEND_ORGANIZATION, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(response => response.json())
        .then(data => {
            setproducts(data.Result)
        })
  }

  useEffect(() => {
    let user = localStorage.getItem('token');
    user = JSON.parse(user);
    if (user) {
      fetchWhishlistStatus(user[0].B2CCustomerId);
      getwhishlist();
    }
  }, []);
  const fetchWhishlistStatus = (customerId) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/B2CCustomerWishList/GetByCustomer?OrganizationId=${process.env.REACT_APP_BACKEND_ORGANIZATION}&CustomerId=${customerId}`)
      .then(res => res.json())
      .then(data => {
        if (data.Code === 200) {
          data.Data.forEach(item => {
            if (item.ProductCode === productcode) {
              setisinwhishlist(true);
            }
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };


  const [productData , setProductdata] = useState(null);

  const [popCount, setPopCount] = useState(0);

  const getProductById = async (code) => {

    fetch(process.env.REACT_APP_BACKEND_URL + '/Product/GetbycodeV2?OrganizationId='+ process.env.REACT_APP_BACKEND_ORGANIZATION+'&ProductCode='+code, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
      }
  })
      .then(response => response.json())
      .then(data => {
          if (data.Status) {
              setProductdata(data.Data[0])
                // let myimgset = []
                // myimgset.push({ id: 1, image: data.Result[0].ProductImagePath })
                // setimageset(myimgset)
                // setproductdata(data.Result[0])
                // setactiveimg(myimgset[0])
                // setProductName( data?.Result?.[0]?.Name || "" )
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
  }


  
  const handleOpen = (code) => {
    getProductById(code);
    getProducts();
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  }

  const addtocartPop = () => {
    let user = localStorage.getItem('token');
    user = JSON.parse(user);

    // Show authentication popup if user is not logged in
    if (!user) {
        setAuthPopupShow(true);
        return;
    }

    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    const itemInCart = cart.find(item => item.data.ProductCode === productData.ProductCode);

    if (itemInCart) {
        // Increment the quantity if the item is already in the cart
        itemInCart.quantity += count;
    } else {
        // Add new item to the cart
        cart.push({ data: productData, quantity: count });
    }

    // Prepare data for API call
    const payload = {
        "OrgId": "3",
        "CartCode": data.CartCode,
        "CustomerCode": user[0].B2CCustomerId, // Fetch CustomerCode from user
        "Qty": itemInCart ? itemInCart.quantity : count, // Ensure correct quantity
        "Price": data.Price,
        "SellingCost": data.SellingCost,
        "ProductCode": data.ProductCode,
        "ProductName": data.ProductName,
        "IsActive": true,
        "CreatedUser": user[0].B2CCustomerName,
        "ModifiedUser": user[0].B2CCustomerName,
        "ProductImage": data.ProductImage,
        "Createdon": data.CreatedOn,
        "Modifiedon": data.ModifiedOn,
        "ChangedOnString": data.ChangedOnString,
        "CreatedOnString": data.CreatedOnString
    };

    // API call to add/update cart details
    fetch(process.env.REACT_APP_BACKEND_URL + '/CartDetails/CreateCartDetails', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    })
        .then(res => res.json())
        .then(responseData => {
            if (responseData.Code === 200) {
                getwhishlist(); // Refresh wishlist
                toast.success('Product added to Cart', {
                    position: "top-right",
                    autoClose: 1000,
                });
                getcartitems(); // Refresh cart items
                setOpen(false); // Close popup

                // Update count for UI
                setshow(true);
                setCount(itemInCart ? itemInCart.quantity - 1 : count - 1);
            }
        })
        .catch(err => {
            console.error('Error adding to cart:', err);
            toast.error('Product could not be added to cart', {
                position: "top-right",
                autoClose: 1000,
            });
        });
};

  

  return (
    <>

            {
                authPopupShow && <AuthPopup />
            }
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{zIndex:'9999'}}
        >
            <Box sx={style} className='pop-responsive'>
                <CloseIcon sx={{ position:'relative' , float:'right' , cursor:'pointer'}} onClick={handleClose} />
                {productData ? (
                <>
                             {productData && (
                <Grid container width='98%' >
                    <Grid item sm={12} md={8} >
                      <Grid container direction='row'>
                        <Grid item md={2.5} sx={{display:'flex' , justifyContent:'center' , alignItems:'flex-start'}}>
                            <Grid container direction='column' justifyContent='center'  alignItems='center'>
                              <Grid item p={2} m={1} sx={{border:'1px solid gray'}}>
                                  <img src={productData.ProductImagePath || noimage} alt='' width='90px' height='110px' />
                              </Grid>
                              {/* <Grid item p={2} m={1} sx={{border:'1px solid #02b290'}}>
                                  <img src={productData.ProductImagePath} alt='' width='90px' height='110px' />
                              </Grid>
                              <Grid item p={2} m={1} sx={{border:'1px solid #02b290'}}>
                                  <img src={productData.ProductImagePath} alt='' width='90px' height='110px' />
                              </Grid> */}
                     
                            </Grid>
                        </Grid>
                        <Grid item md={9} m={1}>
                          <Grid container justifyContent="center" alignItems="center" sx={{ border: '1px solid gray' , padding:'100px 0' }}>
                            <img src={productData.ProductImagePath || noimage} alt="" width="230px" />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <Typography sx={{fontWeight:'500' , fontSize:'20px' , wordBreak:'break-all'}}>{productData.ProductName}</Typography>
                        {/* <Typography>1 each</Typography> */}
                        <Typography sx={{fontWeight:'bolder', fontSize:'20px'}} >S${productData.SellingCost}</Typography>
                        <Typography sx={{color:'#F98F60' , padding:'10px 0'}}>only items left</Typography>
                        <Grid className="calc-box" container sx={{borderRadius:'5px'}}>
                            <Grid item>
                                <RemoveIcon sx={{fontSize:'30px' , cursor:'pointer'}} 
                                   onClick={() => {
                                      if (popCount > 0) {
                                        setPopCount(popCount - 1)
                                        decrementcartqty()
                                      }
                                  }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography  sx={{fontSize:'20px'}}>{popCount}</Typography>
                            </Grid>
                            <Grid item> 
                              <AddIcon   sx={{fontSize:'30px' , cursor:'pointer'}} 
                                  onClick={() => {
                                   if (productData?.EcommerceDetail && productData.EcommerceDetail.length && productData.EcommerceDetail[0].StockAvailability) {
                                       if (popCount < productData.EcommerceDetail[0].QtyOnHand) {
                                        if(popCount < 10){
                                        setPopCount(popCount + 1)
                                        incrementcart()
                                        }
                                      }
                                       else {
                                           toast.error('You have reached maximum quantity', {
                                               position: "bottom-right",
                                               autoClose: 1000,
                                           })
                                       }
                                   }
                                   else {
                                    setPopCount(popCount + 1)
                                    incrementcart()
                                   }
                               }}
                              />
                            </Grid>
                        </Grid>
                        <Grid className="cart-box" container 
                    onClick={handleClose} 
                          >
                            <Grid item>
                                <ShoppingBagOutlinedIcon />
                            </Grid>
                            <Grid item>
                                <Typography  sx={{fontWeight:'bold' , cursor:'pointer'}}>Add to Cart</Typography>
                            </Grid>
                        </Grid>
                        <Grid pt={2}>
                            <Typography sx={{fontWeight:'600'}}>Product Details:</Typography>
                        </Grid>
                        <Grid>
                          <Typography>
                            <div dangerouslySetInnerHTML={{ __html: productData && productData.EcommerceDetail.length && productData.EcommerceDetail[0] && productData.EcommerceDetail[0].Desciption }} />
                          </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
             {showreview ? (
              <>
                  <Grid container pt={2} pb={2} justifyContent='space-between'>
                    <Grid item md={5.5}>
                      <Typography sx={{padding:'20px 0px' , fontSize:'20px'}}>Submit Your review</Typography>
                        <Grid container> 
                          <TextField
                            required
                            fullWidth
                            id="outlined-required"
                            label="Name"
                            sx={{marginBottom:'20px'}}
                          />
                          <TextField
                            required
                            fullWidth
                            id="outlined-required"
                            label="Email"
                            sx={{marginBottom:'20px'}}
                          />
                        <TextField
                            required
                            fullWidth
                            id="outlined-required"
                            label="Review"
                            sx={{marginBottom:'20px'}}
                          />
                          <Rating name="no-value" value={null} sx={{fontSize:'50px'}} />
                        </Grid>
                        <Button sx={{margin:'10px 0',padding:'8px 30px' , backgroundColor:'#02b290' , color:'white' , fontWeight:'bold' , fontSize:'15px'}}>Submit</Button>
                    </Grid>
                    <Grid item md={5.5}>
                        <Typography sx={{padding:'20px 0px' , fontSize:'20px'}}>Product reviews</Typography>

                    </Grid>
                  </Grid>
              </>
             ):(
              <></>
             )}

                <Grid sx={{margin:'30px 0 '}}>
                    <Typography sx={{fontWeight:'bold' ,fontSize:'25px'}}>Related products</Typography>
                </Grid>
                <Grid className="slider-container">
 
                          {PopProducts.length > 0 ? (
                           <>
                                <Slider {...settings}>
                                  {PopProducts && PopProducts.length && PopProducts.map((item , index) => (
                                    <div style={{ display:'flex' , justifyContent:'center' , alignItems:'center'}}>
                                      <Grid item xs={6} sm={4} md={3} lg={3} xl={2.4}  key={index} className="image-hover-effect" sx={{margin:'10px' , minWidth:'200px' }}>
                                        <Card sx={{cursor:'pointer'}} >
                                        <CardContent>
                                            <Grid container direction='column'>
                                                <Grid item sx={{ display: 'flex', justifyContent: 'unset'}} >
                                                    <div>
                                                        <img
                                                        src={item.ProductImagePath || noimage}
                                                        alt='c1'
                                                        width='150px'
                                                        height='160px'
                                                        style={{ objectFit: 'cover', maxWidth: '100%', maxHeight: '100%' , paddingLeft:'10px' }}
                                                        className="image-hover-effect"
                                                    />
                                                    </div>
                                                </Grid>
                                                <Grid item  sx={{zIndex:'9999' , paddingTop:'10px' }} >
                                                    <Grid container sx={{ display:'flex' , flexDirection:'column' , justifyContent:'space-between' ,  minHeight:'150px'}} >
                                                        <Grid item>
                                                            <Typography sx={{fontWeight:'bold' , lineHeight:'1.5rem' ,fontSize:'1rem'}}>S${item.PcsPrice} - S${item.SellingCost}</Typography>
                                                            <Typography sx={{padding:' 10px 0px' , color:'#595959' , fontSize:'14px' , wordBreak:'break-all'}}>{item.ProductName}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography>1 each</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        </Card>
                                      </Grid>
                                    </div>
                                  ))}
                                </Slider>
                            </>
                          ) 

                    : (
                      <></>
                    )}
                </Grid> 
                </>
                ):(
                  <>
                  <div style={{display:'flex' , justifyContent:'center'}}>
                    <img src={logo} alt="Loading..." />
                  </div>
                  </>
                )}

            </Box>
        </Modal>



                <Grid item xs={6} sm={4} md={4} lg={3} xl={2.4}  className="image-hover-effect">
                    <Card sx={{cursor:'pointer'}} 
                    // onClick={() => {
                    //   setproductpopupid(data.ProductCode)
                    //   navigate(`/product/${data.ProductCode}`)
            
                    //   window.location.href = `/product/${data.productshorturl}`
                      
                    // }}
                    >
                    <CardContent>
                      <Grid container direction='column'>
                        <Grid item>
                          {
                             isinwhishlist ? (
                              < FavoriteIcon sx={{float:'right'}} onClick={removewhishlist} />
                             ):(
                              < FavoriteBorderIcon sx={{float:'right'}} onClick={addtowhishlist} />
                             )
                          }
                          <ToastContainer />
                          <Grid pb={2} item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={(e) => {handleOpen(data.ProductCode)}}
                          >
                              <div>
                                  <img
                                      src={data.ProductImagePath || noimage}
                                      alt='c1'
                                      width='110px'
                                      height='120px'
                                      style={{
                                          objectFit: 'cover',
                                          maxWidth: '100%',
                                          maxHeight: '100%',
                                          paddingLeft: '10px'
                                      }}
                                      className="image-hover-effect"
                                  />
                              </div>
                          </Grid>
                          {show ? (
                            <Grid container direction='row' sx={{display:'flex' , justifyContent:'flex-end'}}>
                              <Grid container direction='row' justifyContent='space-evenly' className='calc-box'>
                                <Grid item>
                                    <RemoveIcon sx={{fontSize:'20px' , cursor:'pointer'}} 
                                      onClick={() => {
                                        if (count >= 1) {
                                          setCount(count - 1)
                                          if (count === 1) 
                                            setshow(false)
                                          decrementcartqty()
                                        }
                                        else {
                                          setshow(false)
                                        }
                                      }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography  sx={{fontSize:'16px'}}>{count}</Typography>
                                </Grid>
                                <Grid item>
                                    <AddIcon sx={{fontSize:'20px' , cursor:'pointer'}}
                          onClick={() => {
                                        if (data?.EcommerceDetail && data.EcommerceDetail.length && data.EcommerceDetail[0].StockAvailability) {
                                          if (count < data.EcommerceDetail[0].QtyOnHand) {
                                            if(count < 10){
                                              setCount(count + 1)
                                              incrementcartqty()
                                            }
                                          } else {
                                            toast.error('You have reached the maximum quantity', {
                                              position: "bottom-right",
                                              autoClose: 1000,
                                            })
                                          }
                                        } else {
                                          setCount(count + 1)
                                          incrementcartqty()
                                        }
                                      }}
                                    />
                                </Grid>
                              </Grid>  
                              {/* < CancelIcon onClick={() => setshow(false)} sx={{color:'red' , marginTop:'-31px'}}/> */}
                            </Grid>
                          ):(
                            <Grid container direction='column' alignItems='end' gap={1} sx={{marginTop:'-40px' , minHeight:'70px'}}>
                              <RemoveRedEyeOutlinedIcon className="expand-look" sx={{fontSize:'32px'  , zIndex:'998'}}
                                // onClick={() => {
                                //   setproductpopupid(data.ProductCode)
                                //   navigate(`/product/${data.productshorturl}`)
                                // }}
                                onClick={(e) => {handleOpen(data.ProductCode)}}
                              />
                              <AddIcon className="expand-look" sx={{fontSize:'32px'  , zIndex:'998'}}
                        onClick={() => { 
                                  
                                  addtocart()
                                  setshow(true)
                                }}
                              />
                            </Grid>
                          )}
                   
                        </Grid>
                          <Grid item sx={{ paddingTop: '10px', display: 'flex', justifyContent: 'center' }} onClick={(e) => {handleOpen(data.ProductCode)}}>
                              <Grid container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100px' }}>
                                  <Grid item>
                                      <Typography sx={{ fontWeight: 'bold', lineHeight: '1.5rem', fontSize: '1rem' }}>S$ {
                                       data.SellingCost.toFixed(2)}</Typography>
                                      <Typography sx={{ padding: '10px 0px', fontSize: '12px', wordBreak: 'break-all' }}>{data.ProductName}</Typography>
                                  </Grid>
                              </Grid>
                          </Grid>
                      </Grid>
                    </CardContent>
                    </Card>
                </Grid>

    </>
  )
}

export default ProductCard