import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer2 from '../../COMPONENTS/Footer/Footer2'
import Navbar from '../../COMPONENTS/Navbar/Navbar'
import './Sucess.css';
import { useRecoilState } from 'recoil';
import { cartQuantity } from '../../Providers/CartQuantity';
import { toast } from 'react-toastify';

const Sucess = () => {
  const [ordersuccessorderid, setordersuccessorderid] = React.useState(null);
  const preorderarray = JSON.parse(localStorage.getItem('preorderarray'));
  //localStorage.removeItem('preorderarray');
 // const [reloadnavbar, setreloadnavbar] = React.useState(false);
  const [placeorderCalled, setPlaceorderCalled] = React.useState(false);
  //const [reloadKey, setReloadKey] = React.useState(0); // Add a reload key
  const paymentId = localStorage.getItem('paymentId');
  const [cartqty, setcartqty] = useRecoilState(cartQuantity);

  const RemoveCartDetails = () => {
    let user = localStorage.getItem('token')
    user = JSON.parse(user)
    console.log("customercode",user[0].B2CCustomerId);
    
    const apiUrl = process.env.REACT_APP_BACKEND_URL + '/CartDetails/RemoveByCustomerCode?OrganizationId=' + process.env.REACT_APP_BACKEND_ORGANIZATION + '&Customercode=' + user[0].B2CCustomerId;
    
    console.log("API Endpoint:", apiUrl);
  
    if (!preorderarray?.CustomerId) {
      console.error("CustomerId is missing.");
      return;
    }
  
    fetch(apiUrl, {
      method: 'GET', // Change to DELETE if required
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => {
        console.log("API Response:", data);
        if (data.Message === 'Sucess' && data.Code === 200) {
          toast.success("Cart Removed Successfully");
        } else {
          console.error("API Response Error:", data);
        }
      })
      .catch(err => {
        console.error("Fetch Error:", err);
      });
  };
  
  console.log("preorderarray",preorderarray.CustomerId)

  const placesuccessorder = () => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerOrder/Create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(preorderarray)
    })
    .then(res => res.json())
    .then(data => {
      if (data.Message === 'Sucess' && data.Code === 200) {
        setordersuccessorderid(data.Data);
        const emptyCart = [];
        localStorage.setItem('cart', JSON.stringify(emptyCart));
        RemoveCartDetails();
        //setreloadnavbar(!reloadnavbar);
       // setReloadKey(prevKey => prevKey + 1); // Increment the reload key
      }
      else {
        // Handle order placement failure
      }
    })
    .catch(err => {
      // Handle order placement failure
    });
  }

  useEffect(() => {
    if (!placeorderCalled) {
      placesuccessorder();
      setPlaceorderCalled(true); // Set the flag to true after calling placeorder
    }
    setcartqty(0);

  }, [placeorderCalled, setcartqty]);


  const converttofloat = (value) => {
    value = value.toFixed(2)
    // check if value has decimal
    if (!value.includes('.00')) {
        return parseFloat(value)
    }
    else {
        return parseFloat(value) + 0.001
    }
}


  return (
    <div>
      {/* Pass reloadKey as a prop to Navbar */}
      <Navbar   />
      <div className="success-container">
        <h1 className="success-heading">Thank You for Your Order!</h1>
        <p className="success-message">Your order has been successfully placed. </p>
        <div className="order-summary">
          {/* Display Order details here */}
        </div>
        <div className="shipping-info">
          {/* Display shipping details here */}
        </div>
        <div className="payment-info">
          {/* Display payment details here */}
        </div>
        <p className="order-number">Your Order Number: {ordersuccessorderid}</p>
      
        {/* <p className="order-number">Your Payment Id: {paymentId}</p> */}
        <Link to="/" className="continue-shopping-link">
          <button className="continue-shopping-btn">Continue Shopping</button>
        </Link>
      </div>
    </div>
  )
}

export default Sucess;